import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import Section from '@components/section';
import { RichTextBlock } from 'prismic-reactjs';
import Text from '@components/text';
import {
  Content,
  StyledText,
  StyledButton,
  StyledWrapper,
  StyledImage,
} from '../styles/contact.styled';

interface NotFoundPageProps {
  data: {
    prismicPage404: {
      data: {
        title?: {
          text: string;
        };
        subheading?: {
          richText: RichTextBlock[];
        };
        image?: {
          alt: string;
          url: string;
        };
        link: {
          url: string;
          document: {
            uid: string;
          };
        };
        link_label?: string;
      };
    };
  };
}

const NotFoundPage = ({
  data: {
    prismicPage404: {
      data: { title, subheading, image, link, link_label },
    },
  },
}: NotFoundPageProps): JSX.Element => (
  <Layout isAlternativeHeaderProvided={false} isAlternativeFooterProvided>
    <SEO title="404: Not found" />
    <Section backgroundColor="white">
      <StyledWrapper>
        <Content>
          <Text type="title" stringText={title?.text} />
          <StyledText type="subheading" richText={subheading?.richText} />
          {link?.document.uid !== '' && (
            <StyledButton to={`/${link?.document.uid}`}>{link_label}</StyledButton>
          )}
        </Content>
        <StyledImage src={image?.url} />
      </StyledWrapper>
    </Section>
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
  query Page404Query {
    prismicPage404 {
      ...Page404Fragment
    }
  }
`;
